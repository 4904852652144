import React from "react";

export const Fever: React.FC = () => {
  return (
    <svg viewBox="0 0 34 63" fill="none">
      <path
        d="m14.285 14.182-9.95 27.33c-.36.98-.5 2-.42 3.04l.12 1.65c.12 1.71-.11 3.39-.69 4.99l-.79 2.16 6.62 2.41.84-2.31c.55-1.52 1.39-2.88 2.48-4.07l1.25-1.34c.67-.72 1.18-1.55 1.51-2.47l9.97-27.4c1.09-2.99-.46-6.3-3.45-7.39l-.09-.03c-2.99-1.1-6.31.44-7.4 3.43"
        fill="#A7E6D7"
      />
      <path
        d="M.805 57.942c-.65 1.79.33 3.79 2.13 4.45 1.8.65 3.84-.25 4.49-2.04l1.68-4.62-6.62-2.41z"
        fill="#7FADE3"
      />
      <path
        d="M18.025 31.052c-.16.42-.46.75-.86.94-.39.18-.86.21-1.27.06l-3.37-1.23c-.41-.15-.75-.45-.93-.85-.19-.41-.21-.86-.06-1.28l4-10.99c.15-.42.46-.75.86-.94.39-.19.85-.21 1.27-.06l3.38 1.23c.86.32 1.31 1.27.99 2.13z"
        fill="#7FADE3"
      />
      <path
        d="m28.185 16.212 3.87-2.32-3.7-2.58 1.77-4.15-4.51.08-1.01-4.4-3.6 2.73-3.4-2.97-1.31 4.32-4.5-.4 1.48 4.26"
        stroke="#7FADE3"
      />
    </svg>
  );
};
