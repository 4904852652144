import React from "react";

export const KidneyDisease: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230 160">
      <path
        fill="#a7e6d7"
        d="M95.69 50.41l-1.11-.15-6.71-5.31a1.94 1.93-49.9 00-2.65.23l-6.59 7.36a1.73 1.72-48.9 00.16 2.46q9.82 8.41 21.48 14.11.86.29 1.16.63.46 6.75 1.08 13.01.19 1.99-.64 7.2c-2.02 12.62-8.25 21.16-21.11 24.95q1.44-18.38-9.81-30.08-7.09-7.36-16.96-10.07Q40.33 71.01 26.58 78q-1.94-9.12-2.23-15.62-.19-4.24.69-9C28.89 32.5 41.4 12.21 61.28 3.28 69.21-.28 77.85 0 86.53.46c11.11.6 18.39 7.85 18.59 19.24q.17 9.78-3.58 16.64-2.77 5.04-5.07 9.9-.74 1.58-.78 4.17zM144.02 70.37l1.22-.64q13.07-5.59 23.82-13.98a.85.84-38 00.15-1.18l-6.79-8.74q-.88-1.14-2.08-.32l-8.95 6.13q-.49.24-1.31.32 1.05-1.99.39-3.71-1.82-4.72-5.53-11.2c-4.07-7.11-5.64-18.61-1.94-26.39 3.02-6.35 8.93-9.73 16.01-10.17q11.93-.74 18.52.52c11.18 2.13 22.14 10.5 28.8 19.25q8.84 11.58 12.94 25.2c3.39 11.21 2.69 22.89-.2 34.44-4.89 19.59-21.83 32.32-41.05 35.95q-4.98.95-10.78-.47c-13.78-3.37-22.21-12.98-23.14-27.64q-.48-7.6.43-16.75.04-.42-.51-.62z"
      />
      <path
        fill="#539cbf"
        d="M94.58 50.26c.83 1.63.98 3.49 1.38 5.25.27 1.23.69 2.5 1.29 3.6q2.4 4.35 3.92 8.91a1.01.98 60.2 01-.27 1.06q-.08.06-.63.03Q88.61 63.41 78.79 55a1.73 1.72-48.9 01-.16-2.46l6.59-7.36a1.94 1.93-49.9 012.65-.23l6.71 5.31zM145.24 69.73q-.14-3.63.99-5.51 3.5-5.83 5.16-12.58l8.95-6.13q1.2-.82 2.08.32l6.79 8.74a.85.84-38 01-.15 1.18q-10.75 8.39-23.82 13.98z"
      />
      <path
        fill="#7fade3"
        d="M94.58 50.26l1.11.15q5.02 3.9 13.8 7.87 10.85 4.91 22 1.93 9.48-2.53 18.59-8.25.82-.08 1.31-.32-1.66 6.75-5.16 12.58-1.13 1.88-.99 5.51l-1.22.64-12.84 4.04a.86.85-8.9 00-.6.81l-.03 82.87a1.39 1.38-89.8 01-1.39 1.39l-10.8-.03a1.12 1.12 0 01-1.12-1.02q-.2-2.38-.2-5.01-.02-40.01.13-77.62a1.38 1.38 0 00-1.12-1.37q-6.87-1.33-14.62-4.69-.3-.34-1.16-.63.55.03.63-.03a1.01.98 60.2 00.27-1.06q-1.52-4.56-3.92-8.91c-.6-1.1-1.02-2.37-1.29-3.6-.4-1.76-.55-3.62-1.38-5.25zM80.76 114.9q-1.02 4.8-1.49 6.11c-5.45 15.34-18.67 25.36-34.99 25.2q-16.72-.16-27.66-12.86c-6.22-7.23-9.14-16.7-8.39-26.27Q9.69 88.47 26.58 78q13.75-6.99 27.41-3.25 9.87 2.71 16.96 10.07 11.25 11.7 9.81 30.08zm-32.45.81q-.99.89-.96-.44l.35-19.64a2.52 2.51 18.8 00-1.02-2.06q-1.63-1.19-2.98-.64-2.11.85-2.13 2.27-.18 11.01-.02 20.01.02 1.38-.96.41-2.01-1.99-3.85-3.74-1.04-.99-3.21.01-1.52.7-.52 4.28a2.12 2.07-75.9 00.56.96l8.74 8.75a3.03 3.03 0 004.28.01l9.16-9.12q1.74-1.73.66-3.93-.23-.47-.65-.74-2.57-1.66-4.57.67-1.32 1.53-2.88 2.94z"
      />
    </svg>
  );
};
