import React from "react";

export const CircleClipboard: React.FC = () => {
  return (
    <svg role="img" aria-label="1-2 days icon" viewBox="0 0 230 164" fill="none">
      <g style={{ transform: "translateY(2px)" }}>
        <path
          fill="#ACDCD2"
          d="M150.777 27.366H77.16a6.103 6.103 0 00-6.103 6.103v93.462a6.103 6.103 0 006.103 6.103h73.617a6.103 6.103 0 006.103-6.103V33.469a6.103 6.103 0 00-6.103-6.103z"
        />
        <path
          fill="#82ADDC"
          d="M92.507 34.984h42.923a2.373 2.373 0 002.373-2.374v-4.769c0-3.933-3.209-7.142-7.142-7.142h-8.453c-1.696-2.916-4.815-4.77-8.228-4.77-3.413 0-6.532 1.854-8.227 4.77h-8.454c-3.933 0-7.142 3.21-7.142 7.142v4.77a2.373 2.373 0 002.373 2.373h-.023z"
        />
        <path
          fill="#fff"
          d="M113.958 18.777a2.277 2.277 0 00-2.283 2.283 2.276 2.276 0 002.283 2.283 2.276 2.276 0 002.282-2.283 2.276 2.276 0 00-2.282-2.283z"
        />
        <path
          stroke="#82ADDC"
          strokeMiterlimit="10"
          strokeWidth="6"
          d="M113.98 158.598c43.404 0 78.59-35.186 78.59-78.59s-35.186-78.59-78.59-78.59-78.59 35.186-78.59 78.59 35.186 78.59 78.59 78.59z"
        />
        <path
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M142.121 96.395c-5.469 9.923-16.025 16.636-28.163 16.636-11.256 0-21.156-5.786-26.897-14.534"
        />
        <path
          fill="#fff"
          d="M99.153 98.927l-.43 2.237-10.94-2.08-1.265 11.054-2.26-.249 1.56-13.494 13.335 2.532z"
        />
        <path
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M86.766 64.684c5.583-9.539 15.913-15.935 27.757-15.935 10.216 0 19.302 4.769 25.202 12.182"
        />
        <path
          fill="#fff"
          d="M127.654 61.632l.226-2.26 11.076 1.085.271-11.143 2.26.045-.316 13.584-13.517-1.31zM101.903 66.98v-1.505h3.314v16.454h-3.385V71.281c-.259.07-.541.094-.893.094h-2.75v-2.797h2.444c.94 0 1.27-.635 1.27-1.599zm13.896 9.613h-8.227V73.89h8.227v2.703zm1.06 5.336V79.32l6.253-5.171c1.434-1.176 2.327-2.163 2.327-3.503 0-1.622-1.058-2.468-2.562-2.468-1.575 0-2.657 1.081-2.657 2.868v.258h-3.408v-.47c0-3.103 2.327-5.5 6.088-5.5 3.855 0 5.947 2.092 5.947 5.171 0 2.421-1.316 3.902-3.385 5.571l-3.69 3.01h7.169v2.843h-12.082zM104.8 88.984v-3.238h1.826v9.066h-1.593l-.182-.92c-.44.57-1.088.998-2.046.998-1.788 0-3.096-1.308-3.096-3.42 0-2.06 1.308-3.393 3.083-3.393.919 0 1.58.362 2.008.907zm-1.594 4.416c.998 0 1.619-.777 1.619-1.904 0-1.14-.621-1.93-1.619-1.93-.997 0-1.632.778-1.632 1.917 0 1.14.635 1.917 1.632 1.917zm11.04-.13h.207v1.542h-.933c-.945 0-1.243-.453-1.23-1.088-.466.738-1.14 1.166-2.176 1.166-1.438 0-2.474-.687-2.474-1.956 0-1.412 1.062-2.215 3.057-2.215h1.321v-.324c0-.596-.428-.984-1.192-.984-.712 0-1.191.324-1.269.816h-1.762c.13-1.295 1.321-2.15 3.083-2.15 1.865 0 2.94.829 2.94 2.422v2.357c0 .35.143.415.428.415zm-2.228-1.204v-.13h-1.334c-.764 0-1.205.312-1.205.868 0 .467.376.765.985.765.958 0 1.541-.596 1.554-1.503zm4.755 2.785l-2.565-6.696h1.943l1.619 4.74 1.58-4.74h1.956l-2.953 7.603c-.414 1.1-.751 1.645-1.788 1.645h-1.774V95.86h1.01c.467 0 .622-.116.803-.57l.169-.44zm4.678-2.254h1.736c.065.583.57.985 1.463.985.765 0 1.231-.247 1.231-.713 0-.544-.453-.582-1.528-.725-1.555-.181-2.759-.531-2.759-1.904 0-1.321 1.152-2.19 2.862-2.176 1.787 0 3.018.79 3.109 2.15h-1.749c-.052-.531-.557-.855-1.295-.855-.713 0-1.179.26-1.179.687 0 .505.57.557 1.529.673 1.528.156 2.797.505 2.797 2.008 0 1.32-1.243 2.163-3.031 2.163-1.852 0-3.108-.868-3.186-2.293z"
        />
      </g>
    </svg>
  );
};
