import React from "react";

export const CircleIdea: React.FC = () => {
  return (
    <svg role="img" aria-label="Headache icon" viewBox="0 0 230 164" fill="none">
      <g style={{ transform: "translateY(2px)" }}>
        <path
          fill="#ACDCD2"
          d="M153.746 80.16c-.552-1.264-1.104-2.507-1.657-3.772-.69-1.588-1.196-3.267-1.91-4.878-.897-2.002-2.162-4.625-.828-6.719 1.726-2.714.759-6.442-.115-9.34-.943-3.107-2.324-6.052-3.543-9.043-.99-2.44-2.301-4.763-2.807-7.386a5.375 5.375 0 01-.092-.598c-.069-.783 0-1.542-.092-2.324-.161-1.288-.736-2.508-1.542-3.52-1.978-2.485-5.062-3.866-7.938-4.878-7.293-2.508-15.415-3.152-23.054-2.715-12.632.713-24.596 7.593-30.946 21.076-6.373 13.482-4.717 29.243 3.681 41.277 4.625 7.34 1.404 13.781-.092 27.081 8.927 29.036 40.173 18.958 40.173 18.958s-.438-1.656 0-9.594c.138-2.646 1.196-7.547 3.98-8.743 1.105-.484 2.416-.461 3.59-.484 2.139-.045 4.256-.045 6.396-.161 2.416-.115 5.016-.391 7.201-1.541 1.657-.875 2.784-2.301 3.083-4.211.276-1.725.047-3.635-.299-5.315-.161-.874-.552-1.794-.552-2.692 0-1.219.99-1.495 1.588-2.37.943-1.334-.092-2.231-.483-3.45-.414-1.312.046-.622.92-1.266 3.29-2.44-.345-3.958-.575-6.65-.138-1.518 1.357-1.817 2.508-1.955 1.334-.139 3.267.16 3.98-1.335.552-1.15-.207-2.439-.667-3.474l.092.023z"
        />
        <path
          stroke="#82ADDC"
          strokeMiterlimit="10"
          strokeWidth="6"
          d="M194 80c0 43.63-35.37 79-79 79s-79-35.37-79-79S71.37 1 115 1s79 35.37 79 79z"
        />
        <path
          fill="#82ADDC"
          d="M88.195 60.35c10.318 0 18.683-8.364 18.683-18.682 0-10.318-8.365-18.683-18.683-18.683S69.513 31.35 69.513 41.668c0 10.319 8.364 18.683 18.682 18.683z"
        />
        <path
          fill="#fff"
          d="M83.916 52.735c-.46-.276-.506-.414-.322-.943.92-2.807 1.864-5.591 2.784-8.398.023-.07.046-.161.069-.253H82.49c-.553 0-.76-.483-.553-.898 1.795-3.727 3.566-7.455 5.338-11.182.138-.299.345-.46.713-.46h5.454c.344 0 .528.115.598.368.069.207 0 .368-.092.53a478.984 478.984 0 00-3.337 5.843c-.207.369-.437.737-.667 1.15h4.003c.46 0 .714.3.622.668-.023.115-.115.23-.184.322-2.715 3.566-5.407 7.133-8.122 10.676a74.12 74.12 0 01-1.634 2.117c-.115.138-.276.253-.437.368h-.253l-.023.092z"
        />
      </g>
    </svg>
  );
};
