import React from "react";

export const Communication: React.FC = () => {
  return (
    <svg role="img" aria-label="Communication with your patients" viewBox="0 0 100.493 80">
      <path
        d="M58.627 0H8.373A8.387 8.387 0 0 0 0 8.374v33.493a8.387 8.387 0 0 0 8.373 8.373h8.374v8.373c0 .587.306 1.134.826 1.44.267.16.56.24.854.24.28 0 .546-.066.8-.213 0 0 15.44-8.427 18.053-9.84h21.347A8.387 8.387 0 0 0 67 41.867V8.374A8.387 8.387 0 0 0 58.627 0"
        fill="#7fade3"
      />
      <path
        d="M33.493 28.08v33.493a8.387 8.387 0 0 0 8.374 8.374h21.346c2.6 1.413 18.053 9.84 18.053 9.84.254.133.52.213.8.213a1.693 1.693 0 0 0 1.68-1.68v-8.373h8.374a8.387 8.387 0 0 0 8.373-8.374V28.08a8.387 8.387 0 0 0-8.373-8.373H41.88c-4.627 0-8.387 3.746-8.387 8.373"
        fill="#a7e6d7"
      />
    </svg>
  );
};
