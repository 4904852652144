import React from "react";

export const CircleNoExclamation: React.FC = () => {
  return (
    <svg role="img" aria-label="Mild or Moderate icon" viewBox="0 0 230 164" fill="none">
      <g style={{ transform: "translateY(2px)" }}>
        <path fill="#ACDCD2" d="M105.183 24.872h29.612l-4.303 75.191h-20.684l-4.625-75.191z" />
        <path
          fill="#82ADDC"
          d="M120 135.105c6.519 0 11.803-5.285 11.803-11.803 0-6.519-5.284-11.804-11.803-11.804-6.519 0-11.803 5.285-11.803 11.804 0 6.518 5.284 11.803 11.803 11.803z"
        />
        <path
          stroke="#82ADDC"
          strokeMiterlimit="10"
          strokeWidth="6"
          d="M66.993 22l111.541 111.54M199 80c0 43.63-35.369 79-79 79-43.63 0-79-35.37-79-79S76.37 1 120 1c43.631 0 79 35.37 79 79z"
        />
      </g>
    </svg>
  );
};
