import React from "react";

export const LowOxygen: React.FC = () => {
  return (
    <svg viewBox="0 0 53 61" fill="none">
      <path
        d="M21.245 60.6016C32.755 60.6016 42.075 49.8516 42.075 36.6016C42.075 18.6016 21.245 0.601562 21.245 0.601562C21.245 0.601562 0.415039 18.6016 0.415039 36.6016C0.415039 49.8516 9.74504 60.6016 21.245 60.6016Z"
        fill="#A7E6D7"
      />
      <path d="M21.245 56.6016C11.965 56.6016 4.41504 47.6316 4.41504 36.6016" stroke="#7FADE3" />
      <path d="M21.275 18.6016L21.235 42.6016" stroke="white" />
      <path d="M26.245 37.6016L21.245 42.6016L16.245 37.6216" stroke="white" />
      <path
        d="M42.0851 40.6016C47.6079 40.6016 52.0851 36.1244 52.0851 30.6016C52.0851 25.0787 47.6079 20.6016 42.0851 20.6016C36.5622 20.6016 32.0851 25.0787 32.0851 30.6016C32.0851 36.1244 36.5622 40.6016 42.0851 40.6016Z"
        fill="#7FADE3"
      />
      <path
        d="M40.0251 34.7715C37.5551 34.7715 35.8851 32.9815 35.8851 30.6015C35.8851 28.2215 37.5551 26.4315 40.0251 26.4315C42.4951 26.4315 44.1651 28.2215 44.1651 30.6015C44.1651 32.9715 42.4951 34.7715 40.0251 34.7715ZM40.0251 32.9915C41.2151 32.9915 41.9651 31.9715 41.9651 30.6015C41.9651 29.2315 41.2151 28.2115 40.0251 28.2115C38.8351 28.2115 38.0851 29.2315 38.0851 30.6015C38.0851 31.9715 38.8351 32.9915 40.0251 32.9915Z"
        fill="white"
      />
      <path
        d="M46.4451 32.4215C46.8451 32.1015 47.0451 31.8515 47.0451 31.5415C47.0451 31.1415 46.7951 30.9415 46.4351 30.9415C46.1051 30.9415 45.8351 31.2115 45.8351 31.6515V31.7215H44.6151V31.5815C44.6151 30.6715 45.3151 29.9415 46.4251 29.9415C47.6351 29.9415 48.2551 30.5815 48.2551 31.4915C48.2551 32.1915 47.9051 32.6015 47.2951 33.0915L46.4251 33.7815H48.2751V34.7715H44.6551V33.8615L46.4451 32.4215Z"
        fill="white"
      />
    </svg>
  );
};
