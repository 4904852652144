import React from "react";

export const FileChecked: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="136" height="160" fill="none">
      <path d="M103.731 160H.259V.494h77.604l25.868 26.584V160Z" fill="#A7E6D7" />
      <path d="M103.731 27.078H77.863V.494l25.868 26.584Z" fill="#7FADE3" />
      <path
        d="M16.178 40.53h31.837M16.178 56.48h71.634M16.178 72.431h71.634M16.178 88.382h47.756M16.178 104.332h31.837"
        stroke="#7FADE3"
      />
      <path
        d="M103.731 144.049c17.583 0 31.837-14.282 31.837-31.901 0-17.618-14.254-31.901-31.837-31.901-17.584 0-31.838 14.283-31.838 31.901 0 17.619 14.254 31.901 31.838 31.901Z"
        fill="#7FADE3"
      />
      <path d="m88.449 110.101 11.461 12.256 19.103-20.417" stroke="#fff" />
    </svg>
  );
};
