import React from "react";

export const Asthma: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230 160">
      <path
        fill="#7fade3"
        d="M108.4 80.32q-5.56-8.89-13.92-16.5-1.11-4.42 2.1-7.79 10.76.57 18.94 7.57 3.37 4.25 7.33 8.16 4.22 4.16 7.26 5.11c4.75 1.48 11.13.63 14.1-3.84 2.99-4.49 4.49-10.12 4.58-15.44q.08-4.18-2.12-9.7-2.88-7.24-5.05-13.79c-2.2-6.67-5.79-10.71-10.94-15.08-3.62-3.09-7.62-3.7-11.89-4.76q-4.23-1.05-5.83-2.23-3.69-2.71-4.08-6.21-.46-4.19 3.1-5.27c3.47-1.05 9.55.43 13.48 1.1q6.62 1.12 14.85 7.51 9.66 7.51 14.84 15.31c3.87 5.83 6.83 12.93 9.78 19.31q2.29 4.94 5.49 8.61 5.07 5.8 5.24 14.11c.13 6.48-.28 12.67 0 19.05q.52 11.84 1.84 22.2.71 5.55 2.29 10.51 4.37 13.71 9.26 28.19a1.34 1.33-23.7 01-.63 1.6q-5.2 2.83-11.56 5.46c-8.68 3.59-18.15 4.9-27.54 6.13a1.24 1.23 81.3 01-1.38-1.02q-1.97-11.39-4.19-21.22c-1.16-5.14-2.79-11.41-7.57-14.37-11.36-7.01-16.96-21.75-22.42-33.26q-2.29-4.84-5.36-9.45z"
      />
      <path
        fill="#a7e6d7"
        d="M122.77 26.9q-.31.74-.4 1.99-.08 1.13-.85 1l-19.19-3.61 1.94-11.25a1.51 1.5 10.3 011.77-1.22l17.07 3.34a1.53 1.52 11 011.21 1.78l-1.55 7.97z"
      />
      <path
        fill="#7fade3"
        d="M120.09 40.39l.88-6.01q1.35-1.65.55-4.49.77.13.85-1 .09-1.25.4-1.99 5.43-2.34 7.24-2.85c3.73-1.04 7.06 4.92 8.1 7.68q2.97 7.92 5.99 16.01.99 2.66-1.06.7-4.83-4.62-9.97-9.14c-1.72-1.51-3.15-2.34-5.59-1.78q-3.75.87-7.39 2.87z"
      />
      <path
        fill="#539cbf"
        d="M102.33 26.28l19.19 3.61q.8 2.84-.55 4.49l-19.44-3.81q-.02-1.83.8-4.29z"
      />
      <path
        fill="#a7e6d7"
        d="M101.53 30.57l19.44 3.81-.88 6.01q-.76 1.28-.34 2.55l-2.03 9.14a1.03.92 21.4 00.53 1.05q.15.08.74-.03-2.2.63-2.12 2.9.03 1.03.54.71l-.78 1.72q-.82 1.75-1.11 5.17-8.18-7-18.94-7.57l4.95-25.46z"
      />
      <path
        fill="#7fade3"
        d="M118.99 53.1q-.59.11-.74.03a1.03.92 21.4 01-.53-1.05l2.03-9.14c2.78-1.4 8.64-4.78 11.36-2.52q6.89 5.74 13.8 11.93a5.16 5.15-76.3 011.55 2.55q.26 1.01.19 2.02-.09 1.26-1.03.42-6.27-5.6-11.31-9.41c-3.53-2.66-12.02 3.48-15.32 5.17z"
      />
      <path
        fill="#7fade3"
        d="M116.63 58.43l.78-1.72q5.59-3.63 11.52-6.6c1.49-.75 3.52-1.1 4.93.13q5.75 5.01 11.76 10.36.89.78.56 1.92-.08.3-.31.51a.68.68 0 01-.9.02l-8.36-7.1a3.58 3.57-34.4 00-3.47-.65q-3.27 1.13-6.2 3.13-2.46 1.68-4.85 1.9-3.04.27-5.46-1.9z"
      />
      <rect
        width="17.86"
        height="4.1"
        x="-8.93"
        y="-2.05"
        fill="#a7e6d7"
        rx="1.99"
        transform="rotate(17.2 -187.155 240.304)"
      />
      <path
        fill="#a7e6d7"
        d="M94.48 63.82q8.36 7.61 13.92 16.5-1.85 1.85-5.15 1.92-5.74.13-11.44 0a1.55 1.55 0 01-1.48-1.89q.23-1.04.05-2.07-.09-.55-.64-.49-3.94.38-8.23.09-.2-.02-.21-.22l-.06-8.65q0-.25.25-.25l8.25-.01q.74 0 .74-.74l.02-2.5a1.3 1.29 2.1 011.38-1.28q1.89.14 2.6-.41z"
      />
      <rect
        width="35.46"
        height="4.06"
        x="-17.73"
        y="-2.03"
        fill="#a7e6d7"
        rx="1.83"
        transform="rotate(-5.2 865.65 -596.776)"
      />
      <rect
        width="18"
        height="4.04"
        x="-9"
        y="-2.02"
        fill="#a7e6d7"
        rx="1.96"
        transform="rotate(-27.7 204.331 -88.143)"
      />
    </svg>
  );
};
