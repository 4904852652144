export const ChestPain: React.FC = () => {
  return (
    <svg viewBox="0 0 73 63" fill="none">
      <path
        d="M45.71 3.25159C44.66 7.51159 40.83 10.6616 36.25 10.6616C31.67 10.6616 27.84 7.50159 26.79 3.25159L0.339966 8.38159L4.43997 23.2816H14.04V62.4016H58.46V23.2816H68.06L72.16 8.38159L45.71 3.25159Z"
        fill="#A7E6D7"
      />
      <path
        d="M57.67 22.8116L55.76 20.8316C55.13 20.1816 54.68 19.4016 54.31 18.7116C51.72 13.9216 48.86 12.7716 47.39 12.5116C46.92 12.4316 46.46 12.4116 46.02 12.4616C44.99 12.5816 44.18 13.0916 44.02 13.7216L44 13.8116C43.79 15.1816 44.87 15.4616 46.04 15.7616C46.46 15.8616 46.86 15.9816 47.24 16.1216C48.1 16.4816 48.78 17.1616 49.14 18.0216C49.21 18.1816 49.21 18.3916 49.14 18.5516C49.01 18.8616 48.68 19.0416 48.35 18.9816C48.3 18.9716 48.25 18.9616 48.21 18.9416L37.97 14.8016H37.95C37.88 14.7816 37.81 14.7616 37.74 14.7516C37 14.6216 36.27 15.0416 36 15.7416C35.85 16.1316 35.86 16.5716 36.03 16.9516C36.07 17.0516 36.13 17.1416 36.19 17.2316C35.79 17.0716 35.4 16.9116 35.04 16.7716C34.94 16.7316 34.84 16.7016 34.74 16.6916C34 16.5616 33.29 16.9516 33 17.6416C32.84 18.0316 32.83 18.4616 33 18.8516C33.16 19.2516 33.47 19.5616 33.87 19.7316L35.01 20.1916C34.49 20.2916 34.03 20.6416 33.84 21.1416C33.51 21.9416 33.89 22.8716 34.71 23.2216L35.08 23.3716C34.57 23.4716 34.12 23.8216 33.9 24.3416C33.59 25.1416 33.97 26.0616 34.78 26.4016L43.5 29.9316C44.97 30.5216 48.13 32.1616 50.88 32.1316C53.53 32.1016 53.46 31.3916 54.17 31.2816L62.28 34.5816V24.6316L57.68 22.8116H57.67Z"
        fill="#7FADE3"
      />

      <path
        d="M33.0199 30.0716L26.2999 31.4416L27.7999 24.7616L21.5599 21.9316L26.6899 17.3916L23.3099 11.4416L30.1299 10.7916L30.8899 3.98162L36.7899 7.47162L41.4099 2.41162L44.1399 8.69162"
        stroke="#7FADE3"
      />
    </svg>
  );
};
