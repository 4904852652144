import React from "react";

export const CheckClipboard: React.FC = () => {
  return (
    <svg
      role="img"
      aria-label="Efficacy icon"
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="80"
      fill="none"
      viewBox="0 0 81 80"
    >
      <path
        fill="#ACDCD2"
        d="M58.553 15.568H24.567a2.819 2.819 0 00-2.819 2.818V61.54a2.819 2.819 0 002.819 2.82h33.986a2.819 2.819 0 002.819-2.82V18.386a2.819 2.819 0 00-2.82-2.818z"
      />
      <path
        fill="#82ADDC"
        d="M31.657 19.086h19.817c.604 0 1.102-.498 1.102-1.102v-2.205a3.31 3.31 0 00-3.306-3.306h-3.911a4.416 4.416 0 00-3.805-2.204c-1.579 0-3.02.858-3.804 2.204h-3.91a3.31 3.31 0 00-3.307 3.306v2.205c0 .604.498 1.102 1.102 1.102h.021z"
      />
      <path
        fill="#fff"
        d="M41.555 11.604c-.583 0-1.06.477-1.06 1.06 0 .583.477 1.06 1.06 1.06.583 0 1.06-.477 1.06-1.06 0-.583-.477-1.06-1.06-1.06z"
      />
      <path
        fill="#82ADDC"
        d="M41.555 52.987c6.222 0 11.265-5.044 11.265-11.265 0-6.221-5.043-11.265-11.265-11.265-6.221 0-11.265 5.044-11.265 11.265 0 6.221 5.044 11.265 11.265 11.265z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M36.15 41.001l4.06 4.324 6.76-7.206"
      />
      <path
        stroke="#82ADDC"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M40.505 78.95c21.51 0 38.946-17.436 38.946-38.945S62.014 1.06 40.505 1.06C18.996 1.06 1.56 18.496 1.56 40.005c0 21.51 17.436 38.946 38.945 38.946z"
      />
    </svg>
  );
};
