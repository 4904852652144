import React from "react";

export const HeartFailure: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230 160">
      <path
        fill="#7fade3"
        d="M122.33 0h8.95q3.83 1.08 3.93 6 .09 5.01-.03 9.85a1.78 1.78 0 001.21 1.73q3.84 1.33 7.35 3.51a.45.44-51 01.07.7c-7.92 7.55-12.96 16.41-17.11 27.05q-2.31-.9-4.64-1.73c-5.52-1.96-12.52-1.32-17.97.57q-3.62 1.25-6.81 2.23-.36.11-.43 1.1l-.21-1.47q-.51-5.02.5-9.71a.91.89-68.3 00-.4-.95q-4.15-2.62-8.92-5.18c-2.76-1.49-4.69-3.96-3.04-7.21q1.74-3.43 3.85-6.69c1.62-2.49 4.39-2.74 6.82-1.29q3.3 1.97 6.45 3.73 2.39 1.34.85-.92-2.29-3.34-3.97-6.6c-2.73-5.35 2.85-7.47 6.45-9.55 3.47-2 6.51-2.07 8.65 1.89q1.89 3.5 3.79 6.6.51.84.56-.14.21-3.79-.02-7.5-.29-4.8 4.12-6.02z"
      />
      <path
        fill="#539cbf"
        d="M159.81 17.28q3.16 7.85 7.53 14.35.9 1.33 2.73 1.79.51 1.28-1.49 2.07-9.98-5.63-11.37-18.1 2.03-1.16 2.6-.11z"
      />
      <path
        fill="#7fade3"
        d="M159.81 17.28q9.39 5.29 10.26 16.14-1.83-.46-2.73-1.79-4.37-6.5-7.53-14.35z"
      />
      <path
        fill="#a7e6d7"
        d="M157.21 17.39q1.39 12.47 11.37 18.1-3.34 3.24-7.05 6.05-3.44 2.61-7.6 10.49-4.48 8.47-8.5 17.55-5.82 13.12-4.8 28 .49 7.21-3.82 16.05-.72 1.48.48 2.6.26.24.59.33 2.15.56 3.17-1.42 4.23-8.24 4.17-16.53-.04-4.65.1-9.18c.19-6.17 1.82-12.33 4.35-18.03q4.17-9.4 8.6-17.89 1.83-3.48 4.47-6.44a.87.87 0 011.35.06c10.92 14.71 11.74 34.67 11.07 52.57-.74 19.91-10.66 56.96-34.47 60.3h-3.19q-17.4-3.92-30.37-18.24.77-2.38 1.38-5.11 1.25-5.56.21-10.98c-2.55-13.43-14.08-21.55-27.71-21.84-7.88-20.12-3.97-41.1 15.15-52.94q.48-.3.48-1.35l.21 1.47q1.3 9.25 7.8 16.1 1.67 1.76 3.47 3.68-4.18 1.41-9.11 1.3-2.36-.05-2.5 2.3-.04.71.35 1.43a1.81 1.81 0 001.57.94q6.41.04 11.19-1.4 12.3-3.72 17.02-14.73 3.44-8.03 7.09-16.42 7.47-17.15 23.48-26.82zM75.71 67.97a.31.31 0 01-.52.08Q65.35 56.9 57.43 44.58c-1.19-1.85-1.03-3.75.06-5.6q5.66-9.61 16.25-14.8 1.54-.75 2.74.58 9.01 9.98 17.04 21.11a.85.85 0 01-.22 1.2q-12.23 8.22-17.59 20.9z"
      />
      <path
        fill="#539cbf"
        d="M126.7 48.84q-2.69 7.94-6.62 13.98-4.28 6.57-11.96 7.97-1.8-1.92-3.47-3.68-6.5-6.85-7.8-16.1.07-.99.43-1.1 3.19-.98 6.81-2.23c5.45-1.89 12.45-2.53 17.97-.57q2.33.83 4.64 1.73z"
      />
      <path
        fill="#7fade3"
        d="M81.01 103.83c13.63.29 25.16 8.41 27.71 21.84q1.04 5.42-.21 10.98-.61 2.73-1.38 5.11-2.32 4.35-3.29 5.56c-10.81 13.47-30.62 14.49-42.07 1.26-8.92-10.3-8.59-25.16.32-35.58q5.27-6.16 13.7-8.06 2.64-.6 5.22-1.11zm4.09 31.47a.35.35 0 01-.6-.25v-14.48q0-1.8-1.74-2.25-.24-.07-.49-.05-2.28.14-2.27 2.42l.01 13.85q0 1.47-1.01.4l-2.09-2.22a2.1 2.1 0 00-3.07.01l-.43.47q-1.11 1.2.05 2.37l7.09 7.12q1.7 1.71 3.4.01l6.53-6.52q1.3-1.3.57-3-.44-1.03-2.47-.84a1.89 1.88 19 00-1.17.57l-2.31 2.39z"
      />
    </svg>
  );
};
