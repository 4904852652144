import React from "react";

export const Thermometer: React.FC = () => {
  return (
    <svg
      role="img"
      aria-label="Thermometer"
      viewBox="0 0 101 248"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: "rotate(180deg)" }}
    >
      <path
        d="M18.12 152.53C18.1 152.483 18.0733 152.44 18.04 152.4C17.98 152.34 17.95 152.27 17.95 152.19C18.0033 139.77 17.9534 127.626 17.8 115.76C17.7 107.75 18.27 99.7498 17.86 91.7598C17.74 89.3298 17.08 87.1198 15.43 85.2498C6.19003 74.7798 0.360014 63.6298 0.730014 49.3098C0.903348 42.2565 2.31667 35.9231 4.97 30.3098C12.0633 15.2898 23.67 5.8098 39.79 1.8698C50.3833 -0.723534 61.0467 0.499797 71.78 5.5398C102.66 20.0498 110.06 61.0598 85.43 85.2998C83.99 86.7198 83.2733 88.4398 83.28 90.4598C83.38 131.846 83.39 172.7 83.31 213.02C83.28 228.66 74.89 242.49 59.53 246.62C41.79 251.39 23.86 241.55 19.22 224.22C18.4133 221.22 17.98 216.57 17.92 210.27C17.7733 195.863 17.8067 184.026 18.02 174.76C18.18 167.893 18.15 162.146 17.93 157.52C17.8967 156.813 17.9767 155.266 18.17 152.88C18.1834 152.746 18.1667 152.63 18.12 152.53ZM74.51 91.4198C74.53 88.1698 74.56 84.1998 77.06 81.6498C83.88 74.6898 89.34 67.4198 90.95 57.6698C93.88 39.9198 86.17 23.1298 70.71 14.5498C56.46 6.63979 37.26 7.8198 24.46 18.5698C13.1867 28.0298 8.25 40.1198 9.65 54.8398C10.66 65.3898 15.95 73.5998 23.32 80.9298C26.12 83.7098 26.5 87.1198 26.51 90.9298C26.6233 133.623 26.62 174.65 26.5 214.01C26.4666 226.243 32.0933 234.363 43.38 238.37C47.56 239.856 51.69 240.023 55.77 238.87C68.2433 235.336 74.4567 226.706 74.41 212.98C74.2567 170.846 74.29 130.326 74.51 91.4198Z"
        fill="#81ACDA"
      />
      <path
        d="M77.0601 81.6498C74.5601 84.1998 74.5301 88.1698 74.5101 91.4198C74.2901 130.326 74.2568 170.846 74.4101 212.98C74.4568 226.706 68.2434 235.336 55.7701 238.87C51.6901 240.023 47.5601 239.856 43.3801 238.37C32.0934 234.363 26.4667 226.243 26.5 214.01C26.6201 174.65 26.6234 133.623 26.5101 90.9298C26.5001 87.1198 26.1201 83.7098 23.3201 80.9298C15.9501 73.5998 10.6601 65.3898 9.65007 54.8398C8.25007 40.1198 13.1867 28.0298 24.4601 18.5698C37.2601 7.81979 56.4601 6.63978 70.7101 14.5498C86.1701 23.1298 93.8801 39.9198 90.9501 57.6698C89.3401 67.4198 83.8801 74.6898 77.0601 81.6498ZM60.2001 76.0098C60.1997 75.7141 60.2878 75.4244 60.4532 75.1771C60.6187 74.9298 60.8541 74.7359 61.1301 74.6198C73.3501 69.4898 80.2901 55.7398 76.3101 42.8098C71.25 26.3798 53.4401 18.5598 37.8901 26.6098C20.5601 35.5798 18.8801 59.3298 33.9101 71.2598C35.8201 72.7798 38.16 73.6598 40.25 74.9298C40.6167 75.1498 40.8001 75.4731 40.8001 75.8998C41.0201 98.0331 41.0267 120.283 40.8201 142.65C40.7501 150.03 41.4801 157.17 50.2101 157.42C56.7101 157.61 60.2201 152.98 60.1801 146.91C60.0334 123.936 60.0401 100.303 60.2001 76.0098Z"
        fill="white"
      />
      <path
        d="M60.2001 76.0098C60.0401 100.303 60.0334 123.936 60.1801 146.91C60.2201 152.98 56.7101 157.61 50.2101 157.42C41.4801 157.17 40.7501 150.03 40.8201 142.65C41.0267 120.283 41.0201 98.0331 40.8001 75.8998C40.8001 75.4731 40.6167 75.1498 40.2501 74.9298C38.1601 73.6598 35.8201 72.7798 33.9101 71.2598C18.8801 59.3298 20.5601 35.5798 37.8901 26.6098C53.4401 18.5598 71.2501 26.3798 76.3101 42.8098C80.2901 55.7398 73.3501 69.4898 61.1301 74.6198C60.8541 74.7359 60.6187 74.9298 60.4532 75.1771C60.2878 75.4244 60.1997 75.7141 60.2001 76.0098ZM47.5401 59.6398C47.5401 57.9239 46.8584 56.2782 45.6451 55.0648C44.4317 53.8515 42.786 53.1698 41.0701 53.1698C39.3541 53.1698 37.7085 53.8515 36.4951 55.0648C35.2818 56.2782 34.6001 57.9239 34.6001 59.6398C34.6001 61.3558 35.2818 63.0014 36.4951 64.2148C37.7085 65.4282 39.3541 66.1098 41.0701 66.1098C42.786 66.1098 44.4317 65.4282 45.6451 64.2148C46.8584 63.0014 47.5401 61.3558 47.5401 59.6398Z"
        fill="#A9D5CD"
      />
      <path
        d="M41.0701 66.1098C44.6434 66.1098 47.5401 63.2131 47.5401 59.6398C47.5401 56.0665 44.6434 53.1698 41.0701 53.1698C37.4968 53.1698 34.6001 56.0665 34.6001 59.6398C34.6001 63.2131 37.4968 66.1098 41.0701 66.1098Z"
        fill="#81ACDA"
      />
    </svg>
  );
};
