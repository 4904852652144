import React from "react";

export const Diabetes: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230 160">
      <path
        fill="#a7e6d7"
        d="M42.64 77.78q-.29-.06-.52-.56l-7.71-7.82a.51.5 48.6 00-.67-.05q-4.34 3.26-7.9 8.14-.39.34-.42.52-.1-.13-.2-.23a.85.84 67.7 00-.64-.27c-7.81.19-14.79-3.16-15.41-11.41-.73-9.68 7.43-12.56 15.37-12.57q50.85-.05 101.87-.01a2.22 2.19-25.4 011.32.46l10.81 8.53a1.37 1.36-64.6 01.48 1.37c-1.53 6.62-1.87 14.69 1.61 20.76 6.87 11.98 21.54 15.46 34.95 13.43q.51-.08.46-.59l-.07-.92a.68.68 0 00-.76-.62q-7.25.86-13.13.04c-9.26-1.3-18.82-7.29-21.31-16.64-1.33-4.97-.49-10.54.36-15.6q.28-1.67-1.05-2.72c-5.58-4.44-11.41-8.52-16.39-13.65-3.26-3.37-6.58-6.88-10.22-9.82q-5.98-4.83-12.9-10.25c-5.6-4.38-10.69-13.14-6.21-20.09 5.75-8.92 14.56-3.79 21.6-.15q22.9 11.82 44.53 23.93 14.46 8.09 25.71 15.33 4.33 2.79 7.75 5.66c4.96 4.16 9.42 6.2 15.99 6.03q4.85-.13 9.33-.02a.74.74 0 01.73.74v79.49a.74.73-.8 01-.72.73q-6.33.15-12.38 0c-6.36-.16-12.13 2.34-16.71 6.43-10.69 9.53-23.12 11.59-37 11.71q-11.62.1-22.44.01-1.01-.01-2.91 1.21a.06.05-27.1 01-.08-.02l-.11-.23q-.12-.28.15-.4 6.65-2.9 7.92-9.55 2.05-10.82-7.31-16.05-.81-.45.07-.78c3.79-1.4 6.69-6.52 7.34-10.04 1.46-8-3.61-15.58-11.52-16.99q-.62-.11-.12-.5 6.92-5.39 5.97-13.83c-.55-4.96-4.45-9.92-9.32-11.55q-2.44-.82-6.59-.83-35.6-.06-70.74 0-.44 0-.86.24z"
      />
      <path
        fill="#539cbf"
        d="M42.12 77.22q-.15.56-.88.54-7.49-.24-15.4-.27 3.56-4.88 7.9-8.14a.51.5 48.6 01.67.05l7.71 7.82z"
      />
      <path
        fill="#7fade3"
        d="M42.12 77.22q.23.5.52.56c3.97 5.46 5.49 9.78 4.05 16.44q-1.97 9.14-10.75 10.7c-6.53 1.16-12.01-2.9-14.19-9.16q-3.2-9.23 3.67-17.75.03-.18.42-.52 7.91.03 15.4.27.73.02.88-.54z"
      />
      <rect width="65.38" height="24.36" x="62.58" y="79.7" fill="#a7e6d7" rx="11.71" />
      <rect width="56.34" height="24.02" x="77.37" y="106.5" fill="#a7e6d7" rx="11.5" />
      <rect width="45.82" height="23.56" x="88.1" y="132.97" fill="#a7e6d7" rx="10.84" />
    </svg>
  );
};
