import React from "react";

export const HoldHeart: React.FC = () => {
  return (
    <svg
      role="img"
      aria-label="Safety profile icon"
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="80"
      fill="none"
      viewBox="0 0 81 80"
    >
      <path
        fill="#ACDCD2"
        d="M39.762 41.72c.198.197.463.308.738.308.276 0 .54-.11.739-.309l14.633-14.81a9.556 9.556 0 00.794-11.14c-1.422-2.303-3.725-3.769-6.49-4.122-3.252-.418-6.679.739-9.158 3.108l-.518.496-.518-.496c-2.48-2.358-5.906-3.526-9.157-3.108-2.766.353-5.069 1.819-6.49 4.122-2.16 3.493-1.84 7.967.86 11.206L39.761 41.72zM26.109 16.86c1.09-1.763 2.854-2.877 4.992-3.152a8.58 8.58 0 011.069-.066c2.303 0 4.628.937 6.39 2.611l1.235 1.169a1.031 1.031 0 001.432 0l1.235-1.169c2.038-1.939 4.815-2.887 7.46-2.545 2.126.275 3.9 1.4 4.991 3.152 1.686 2.732 1.433 6.236-.562 8.65L40.522 39.504 26.748 25.576a7.483 7.483 0 01-.628-8.716h-.011z"
      />
      <path
        fill="#82ADDC"
        d="M68.06 39.736c-1.808-1.62-4.563-1.565-6.27.12-.375.375-.783.761-1.224 1.169-1.708-1.477-4.264-1.422-5.851.143-2.37 2.347-5.907 4.893-8.661 6.744a4.416 4.416 0 00-2.292-.661h-8.11c-.992 0-2.061-.122-3.262-.375-6.843-1.444-11.582 2.457-13.3 4.198a6.82 6.82 0 01-1.675 1.245l-9.642 6.976a1.043 1.043 0 00-.452 1.4c.187.363.55.561.926.561.165 0 .32-.033.473-.11l9.642-6.986a8.854 8.854 0 002.204-1.631c1.477-1.499 5.554-4.849 11.394-3.626 1.344.287 2.545.42 3.691.42h8.11c1.301 0 2.348 1.068 2.348 2.39 0 1.323-1.058 2.392-2.347 2.392h-9.3a1.035 1.035 0 100 2.072h9.3c1.135 0 2.17-.453 2.953-1.169 0 0 .022 0 .033-.01.463-.342 11.295-8.53 16.496-13.676.925-.914 2.435-.936 3.427-.055.518.463.826 1.113.87 1.807a2.595 2.595 0 01-.672 1.896l-1.256 1.388c-3.945 4.386-9.895 11.008-16.837 15.769-1.202.826-2.645 1.234-4.11 1.223-2.888-.055-8.475-.243-13.422-.805-2.865-.33-5.818.331-8.32 1.84l-7.074 4.43a1.04 1.04 0 00-.353 1.433c.199.32.54.507.893.507a.984.984 0 00.54-.154l7.074-4.43c2.116-1.279 4.606-1.83 7.02-1.554 5.024.573 10.688.76 13.608.816 1.94.032 3.758-.507 5.323-1.587 7.14-4.904 13.2-11.637 17.2-16.1l1.246-1.377a4.66 4.66 0 001.212-3.405 4.66 4.66 0 00-1.554-3.25v.022zm-11.88 2.909c.75-.75 1.94-.816 2.821-.21-3.537 3.13-8.044 6.689-10.843 8.87a4.428 4.428 0 00-.617-1.895c2.777-1.884 6.248-4.407 8.64-6.765z"
      />
      <path
        stroke="#82ADDC"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M40.5 78.898c21.483 0 38.898-17.415 38.898-38.898 0-21.483-17.415-38.898-38.898-38.898C19.017 1.102 1.602 18.517 1.602 40c0 21.483 17.415 38.898 38.898 38.898z"
      />
      <path stroke="#82ADDC" strokeMiterlimit="10" strokeWidth="2" d="M14.186 56.98l8.64 9.797" />
    </svg>
  );
};
