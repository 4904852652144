import React from "react";

export const Lungs: React.FC = () => {
  return (
    <svg viewBox="0 0 76 61">
      <path
        d="M4.745 25.062c4.34-11.75 12.26-16.51 18.18-18.4 2.17-.74 4.5-.33 6.42.9.83.58 1.5 1.31 2 2.14.5.82 1.03 1.81 1.11 2.71.12 2.52.03 27.6 0 27.7-.54 12.78-2.3 14.11-3.2 14.84-2.75 2.05-5.34 1.56-7.42 1.15-2-.41-3.08-.58-4.5.9-2 2.14-5.29 3.49-8.55 3.82-3.42 0-6.79-2.26-7.3-5.38-.99-5.66-1.82-17.07 3.26-30.38m67 0c-4.34-11.75-12.26-16.51-18.18-18.4-2.17-.74-4.5-.33-6.42.9-.83.58-1.5 1.31-2 2.14-.5.82-1.03 1.81-1.11 2.71-.12 2.52-.03 27.6 0 27.7.54 12.78 2.3 14.11 3.2 14.84 2.75 2.05 5.34 1.56 7.42 1.15 2-.41 3.08-.58 4.5.9 2 2.14 5.29 3.49 8.55 3.82 3.42 0 6.79-2.26 7.3-5.38.99-5.66 1.82-17.07-3.26-30.38"
        fill="#A7E6D7"
      />
      <path
        d="M48.395 33.352c.42 2.05 1.42 5.17 4.09 8.38.17.25.5.25.67.08l2.08-1.81c.17-.16.25-.49.08-.66-.92-1.07-1.58-2.22-2.08-3.2-.17-.41.17-.82.58-.66 1.83.66 3.84 1.31 6 1.89.25.08.58-.08.58-.33l.75-2.63c.08-.25-.08-.49-.33-.58-4.5-1.23-10.84-4.35-10.84-4.35s7.25-1.48 8.5-1.97c.25-.08.42-.41.25-.66l-1-2.63c-.08-.25-.33-.33-.58-.25-1.67.58-7.84 2.55-13.67 1.73-.08 0-.17-.08-.25-.08-1.5-1.23-2.42-2.22-2.92-2.79-.08-.08-.08-.16-.08-.33V1.322c0-.25-.25-.49-.5-.49h-2.75c-.25 0-.5.25-.5.49v21.11c0 .08 0 .25-.08.33-.42.58-1.33 1.56-2.92 2.79-.08.08-.17.08-.25.08-5.75.82-11.92-1.07-13.67-1.73-.25-.08-.5 0-.58.25l-1 2.55c-.08.25 0 .58.25.66 1.17.41 8.5 1.97 8.5 1.97s-6.34 3.12-10.84 4.35c-.25.08-.42.33-.33.58l.75 2.63c.08.25.33.41.58.33 2.17-.58 4.17-1.23 6-1.89.42-.16.83.25.58.66-.5.99-1.17 2.05-2.08 3.2-.17.25-.17.49.08.66l2.08 1.81c.25.16.5.16.67-.08 2.67-3.2 3.67-6.32 4.09-8.38 0-.16.17-.25.25-.33 4.75-2.38 7.75-4.76 9.42-6.49.17-.16.5-.16.67 0 1.67 1.73 4.67 4.11 9.42 6.49.17.24.25.32.33.48"
        fill="#7FADE3"
      />
    </svg>
  );
};
