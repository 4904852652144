import { Grid, GridColumn, Text, ThemeOverride, ThemeProvider } from "@modernatx/ui-kit-react";
import { Global } from "theme-ui";

import { Asthma } from "@/components/mresvia/icons/Asthma";
import { CheckClipboard } from "@/components/mresvia/icons/CheckClipboard";
import { ChestPain } from "@/components/mresvia/icons/ChestPain";
import { CircleClipboard } from "@/components/mresvia/icons/CircleClipBoard";
import { CircleIdea } from "@/components/mresvia/icons/CircleIdea";
import { CircleNoExclamation } from "@/components/mresvia/icons/CircleNoExclamation";
import { Communication } from "@/components/mresvia/icons/Communication";
import { Diabetes } from "@/components/mresvia/icons/Diabetes";
import { Fever } from "@/components/mresvia/icons/Fever";
import { FileChecked } from "@/components/mresvia/icons/FileChecked";
import { Fridge } from "@/components/mresvia/icons/Fridge";
import { HeartFailure } from "@/components/mresvia/icons/HeartFailure";
import { HoldHeart } from "@/components/mresvia/icons/HoldHeart";
import { KidneyDisease } from "@/components/mresvia/icons/KidneyDisease";
import { LowOxygen } from "@/components/mresvia/icons/LowOxygen";
import { Lungs } from "@/components/mresvia/icons/Lungs";
import { PulmonaryDisease } from "@/components/mresvia/icons/PulmonaryDisease";
import { Snowflake } from "@/components/mresvia/icons/Snowflake";
import { Syringe } from "@/components/mresvia/icons/Syringe";
import { Thermometer } from "@/components/mresvia/icons/Thermometer";
import { RenderMetadata } from "@/components/RenderMetadata";
import { RenderContent } from "@/components/rsv/RenderContent";
import { ExperienceProvider } from "@/context/ExperienceContext";
import { ExitModalProvider } from "@/hooks/useExitModal";
import { IconProvider } from "@/hooks/useIcon";
import { HccTheme, HcpTheme } from "@/profiles/themes";
import { BlockPageProps } from "@/types/Block";
import { Experience } from "@/utils/experience";

interface MresviaPageProps {
  content: BlockPageProps | null;
  experience: Experience | null;
}

export const MresviaPage: React.FC<MresviaPageProps> = ({ content, experience }) => {
  const { country } = experience || {};

  const background = (
    <Global
      styles={{
        html: {
          backgroundColor: "white"
        }
      }}
    />
  );

  if (!country || !content) {
    return (
      <ThemeProvider>
        {background}
        <Grid>
          <GridColumn
            sx={{
              alignItems: "center",
              display: "flex",
              height: "100vh",
              justifyContent: "center"
            }}
          >
            <Text size="lg" as="p">
              The site is not available in your country.
            </Text>
          </GridColumn>
        </Grid>
      </ThemeProvider>
    );
  }
  const experienceData = experience ?? { country: "us" };
  return (
    <ExperienceProvider experience={experienceData}>
      <ThemeProvider theme={content.theme === "hcc" ? HccTheme : HcpTheme}>
        <ThemeOverride mode={content.themeMode}>
          <Global
            styles={{
              html: {
                backgroundColor: content.backgroundColor as string
              }
            }}
          />
        </ThemeOverride>
        <IconProvider
          icons={{
            "check-clipboard": CheckClipboard,
            "circle-clipboard": CircleClipboard,
            "circle-idea": CircleIdea,
            "circle-no-exclamation": CircleNoExclamation,
            "heart-failure": HeartFailure,
            "hold-heart": HoldHeart,
            "kidney-disease": KidneyDisease,
            "pulmonary-disease": PulmonaryDisease,
            asthma: Asthma,
            diabetes: Diabetes,
            syringe: Syringe,
            thermometer: Thermometer,
            fridge: Fridge,
            snowflake: Snowflake,
            "file-checked": FileChecked,
            lungs: Lungs,
            fever: Fever,
            "low-oxygen": LowOxygen,
            "chest-pain": ChestPain,
            communication: Communication
          }}
        >
          <ExitModalProvider exitModal={content.exitModal}>
            <RenderMetadata {...content.metadata} renderGoogleRecaptcha={true} />
            <RenderContent content={content} />
          </ExitModalProvider>
        </IconProvider>
      </ThemeProvider>
    </ExperienceProvider>
  );
};
